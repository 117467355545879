.cart-container {
  display: flex;
  margin-top: 0.5rem;
  max-width: 50vw;
  min-width: 50vw;
  height: 100vh;
  position: fixed;
  /* background-color: rgba(8, 114, 188, 0.8); */
  flex-direction: column;
  right: 0;
  top: 0;
  z-index: 10000;
  border: black solid 1px;
  transition: transform 500ms ease-in-out;
  transform: translate(100%);
  
}

.mobile-close-cart {
  display: none;
}

.cart-icon {
  position: fixed;
  padding: 0.5em 0.25em 0.5em 0.25em;
  left: -2rem;
  top: 0;
  margin-top: -1px;
  text-align: center;
  font-size: 15px;
  color: #0c375a;
  border: solid black 1px;
  border-right: none;
  border-top-left-radius: 0.25em;
  border-bottom-left-radius: 0.25em;
  box-shadow: 0px 2px 1px -1px #0c375a;
  cursor: pointer;
  background-color: white;
  font-size: 1.25rem;
}

.cart-icon:hover {
  transition: scaleX 100ms ease-in-out;
  transform: scaleX(1.2);
  transition: color 100ms ease-in-out;
  color: #0872bc;
}

.cart-container-show {
  transform: translate(0);
}

.number-in-cart {
  position: fixed;
  left: -2.5rem;
  top: -0.5rem;
  padding: 0.1rem;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  font-weight: bolder;
  background-color: #0872bc;
  color: white;
}

.payment-form {
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  height: fit-content;
}

.c-jWYnUm {
  height: 4rem;
  width: 75%;
  margin-bottom: 5rem;
}

.inner {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.total {
  width: 100%;
  height: 3rem;
  margin-bottom: 2rem;
  text-align: center;
  color: black;
}

@media (max-width: 768px) {
  .cart-container {
    max-width: 90vw;
    min-width: 90vw;
  }
}

@media (max-width: 425px) {
  .cart-container {
    max-width: 100vw;
    min-width: 100vw;
  }

  .mobile-close-cart {
    position: fixed;
    display: block;
    width: 1.1rem;
    height: 1.1rem;
    padding: 0.1rem;
    border-radius: 50%;
    background-color: crimson;
    top: 0.1rem;
    left: 0.1rem;
    cursor: pointer;
  }
  
}