.wrapper {
  width: 90%;
  margin-bottom: 5rem;
  margin-top: 5rem;
  margin-left: auto;
  margin-right: auto;
  max-height: 100vh;
  min-height: 50vh;
  border: solid black 2px;
  padding: 1rem;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
  overflow-y: scroll;
}
