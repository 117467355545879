.wrapper-edit {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.item-form {
  display: flex;

  /* height: 25%; */
  padding: 2rem;
  gap: 1rem;
  flex-direction: column;
  border: solid #0c375a 2px;
  border-radius: 0.25em;
  justify-content: center;
  align-items: center;
  font-size: 12pt;
  margin-left: auto;
  margin-right: auto;
}

button {
  height: 1.5em;
  bottom: 0;
  left: auto;
  right: auto;
  position: relative;
}

input {
  max-height: 2rem;
}
