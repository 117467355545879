.container {
  display: flex;
  justify-content: center;
  border: solid black 1px;
  border-radius: 0.5em;
  min-width: 31rem;
  max-width: 41rem;
  margin-left: auto;
  margin-right: auto;
}

.slide-img {
  max-width: 40rem;
  height: 20rem;
}

@media (max-width: 960px) {
  .container {
    display: none;
  }
}
