/* #0c375a dark blue */
/* #0872bc light blue */
* {
  background-color: #FAF9F6;
}

.App {
  text-align: center;
  /* position: absolute; */
  inset: 0;
  min-height: calc(100% + env(safe-area-inset-top));
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  background-size: cover;
  
}

header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}



.logo {
  width: 10rem;
  aspect-ratio: attr(width) / attr(height);
  padding-top: .25em;
}

footer {
  height: 10vh;
  width: 100%;
  display: block;
  bottom: 0;
  background-color: #0c375a;
}