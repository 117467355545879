.info-container {
  width: 75%;
  line-height: 2em;
  font-family: "Montserrat", sans-serif;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5rem;
}

.marketing {
  color: #0872bc;
  opacity: 0.8;
}

h1 {
  color: #0c375a;
  font-weight: bold;
  opacity: 0.95;
}

.items {
  margin-left: auto;
  margin-right: auto;
}
