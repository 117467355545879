.card {
  display: flex;
  flex-direction: column;
  border: solid black 2px;
  border-radius: 0.25em;
  min-width: 20rem;
  max-width: 20rem;
  min-height: 46rem;
  max-height: 46rem;
  height: fit-content;
  overflow-wrap: break-word;
}

.cart-edit {
  min-height: 35rem !important;
  max-height: 35rem !important;
}

.delete {
  all: unset;
  background-color: crimson;
  margin: 1px;
  margin-left: auto;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  text-align: center;
  transition: transform 50ms ease-in-out;
  cursor: pointer;
}

.delete:hover {
  transform: scale(1.1);
}

.image-section {
  margin: 0.5rem;
  min-height: 15rem;
  min-width: 15rem;
  max-width: 20rem;
  max-height: 15rem;
  border: solid black 2px;
  border-radius: 0.25em;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
  position: relative;
}

.info {
  margin-top: 1rem;
  padding: 1rem;
  overflow-y: scroll;
  color: #0872bc;
}

#shirt .design {
  max-width: 6rem;
  min-height: 12rem;
  min-width: 6rem;
  background-color: rgb(0, 0, 0, 0);
  margin-right: 0.4rem;
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

#shirt .design img {
  max-width: inherit;
  max-height: inherit;
  background-color: inherit;
  position: absolute;
  touch-action: none;
}

#shirt .design img:hover {
  cursor: pointer;
}

#shirt .design img:active {
  cursor: move;
}

#mug .design {
  max-width: 7rem;
  min-width: 7rem;
  min-height: 10rem;
  margin-right: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  background-color: rgb(0, 0, 0, 0);
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
}

#mug .design img {
  max-width: inherit;
  max-height: inherit;
  background-color: inherit;
  position: absolute;
  touch-action: none;
}

#mug .design img:hover {
  cursor: pointer;
}

#mug .design img:active {
  cursor: move;
}

#hat .design {
  max-width: 6rem;
  min-width: 7rem;
  min-height: 4.5rem;
  margin-bottom: 3rem;
  margin-right: 2rem;
  background-color: rgb(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}

#hat .design img {
  max-width: inherit;
  max-height: inherit;
  background-color: inherit;
  position: absolute;
  touch-action: none;
}

#hat .design img:hover {
  cursor: pointer;
}

#hat .design img:active {
  cursor: move;
}

.color-selector {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  gap: 1rem;
}

.color-btn {
  all: unset;
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  border: solid black 1px;
  border-radius: 50%;
  transition: transform 50ms ease-in-out;
}

.color-btn:hover {
  transform: scale(1.5);
}

.white {
  background-color: white;
}

.black {
  background-color: black;
}

.grey {
  background-color: gray;
}

.navy {
  background-color: navy;
}

.blue {
  background-color: blue;
}

.green {
  background-color: green;
}

.pink {
  background-color: pink;
}

.purple {
  background-color: purple;
}

.brown {
  background-color: brown;
}

.add {
  all: unset;
  padding: 0.5rem;
  border: solid black 1px;
  border-radius: 0.25rem;
  width: 6rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  background-color: #0872bc;
  color: white;
  cursor: pointer;
}

.custom-img-btn {
  display: none;
}

.custom-img-lbl {
  background-color: #0872bc;
  font-size: 0.8rem;
  padding: 0.5rem;
  margin-top: 0.25rem;
  border: solid black 1px;
  border-radius: 0.25rem;
  margin-left: auto;
  margin-right: auto;
  width: 6rem;
  color: white;
  cursor: pointer;
}

.qty-selector {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
}

.qty-selector h3 {
  width: 2rem;
}

.inc,
.dec {
  all: unset;
  width: 1.5rem;
  height: 1.5rem;
  border: solid black 1px;
  color: white;
  background-color: #0872bc;
  cursor: pointer;
}

.size-selector,
.item-selector {
  display: flex;
  gap: 0.25rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
}

.size-btn,
.item-btn {
  all: unset;
  background-color: #0872bc;
  border: solid black 1px;
  width: 3rem;
  height: 1.5rem;
  cursor: pointer;
  color: white;
}

.quantity-input {
  text-align: center;
  width: 15%;
  font-size: 1.1rem;
  border: none;
}

.design-size {
  position: absolute;
  display: flex;
  bottom: 0px;
  right: 0px;
  gap: 0.5rem;
  background-color: rgba(0, 0, 0, 0);
}

.design-size button {
  all: unset;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 0.25rem;
  border-radius: 20%;
  width: 1rem;
  height: 1rem;
  cursor: pointer;
}
